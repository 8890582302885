<template>
    <div class="child-collapse-table">
        <div class="child-collapse-table__divider">
            <p>{{ $t('Customers') }}</p>
            <el-divider content-position="left" />
            <DashboardSortSelect :model-value="sortValue" @onSortingChange="handleSelectChange" />
        </div>

        <div class="child-collapse-table__wrapper" :class="{'no-data-loading': loading && !tableData.length }">
            <div class="child-collapse-loading"  v-if="loading">
                <CustomLoader />
            </div>
            <el-table :data="displayData" style="width: 100%" @row-click="handleClickCustomer">
                <el-table-column prop="name" width="300" :label="$t('Name')" class-name="stats-company">
                    <template #default="{ row }">
                        <img :src="row.iconPath" alt="">
                        <span>{{ row.name }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="industries" min-width="200" :label="$t('Industries')" show-overflow-tooltip/>

                <el-table-column prop="revenue" width="150" :label="$t('Revenue (EUR)')" />

                <el-table-column prop="score" :label="$t('Score')"  width="90" class-name="stats-label">
                    <template #default="{ row }">
                        <div class="stats-values__item score-indicator" :class="getStatusClass(row.stats.score)">
                            <span class="stats-values__item--indicator"></span>
                            <div class="stats-values__item--value">{{ numberDecimalsFormat(row.stats.score) }}</div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="importance" :label="$t('Importance')" width="90" class-name="stats-label">
                    <template #default="{ row }">
                        <div class="stats-values__item importance-indicator">
                            <span class="stats-values__item--indicator"></span>
                            <div class="stats-values__item--value">{{ numberDecimalsFormat(row.stats.importance) }}</div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="gap" :label="$t('Gap')" width="90" class-name="stats-label">
                    <template #default="{ row }">
                        <div class="stats-values__item gap-indicator" :class="getGapStatusClass(row.stats.gap)">
                            <span class="stats-values__item--indicator"></span>
                            <div class="stats-values__item--value">{{ numberDecimalsFormat(row.stats.gap) }}</div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="respondents" :label="$t('Respondents')" width="90" class-name="stats-label">
                    <template #default="{ row }">
                        <div class="stats-values__item">
                            <span class="stats-values__item--indicator"></span>
                            <div class="stats-values__item--value">{{ row.stats.respondents }}</div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="scores" :label="$t('Scores')" width="90" class-name="stats-label">
                    <template #default="{ row }">
                        <div class="stats-values__item">
                            <span class="stats-values__item--indicator"></span>
                            <div class="stats-values__item--value">{{ row.stats.scores }}</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <CustomPagination
          v-if="tableData.length"
          class="mt-4 mb-4"
          :disabled="loading"
          :current-page="page"
          :total="tableData.length"
          :page-size="pageSize"
          @currentPageChange="handleCurrentChange"
          @pageSizeChange="handlePageSizeChange"
        />
    </div>
</template>

<script>
import { getStatusClass, numberDecimalsFormat, getGapStatusClass } from "@/core/helpers/GlobalHelper";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader.vue";
import DashboardSortSelect from "@/buying-teams/shared-components/inputs/DashboardSortSelect.vue";
import store from "@/store";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import { BankPageTypes } from "@/store/enums/bank/BankPageTypes";

export default {
    name: "DashboardChildCollapseTable",
    components: {
        DashboardSortSelect,
        CustomLoader,
        CustomPagination
    },
    props: {
        customersContainer: {
            type: Object,
            default: null
        },
        filterData: {
            type: Object,
            default: null
        },
        parentTitle: String,
        childTitle: String,
        pageType: String,
    },
    data() {
        return {
            getStatusClass,
            numberDecimalsFormat,
            getGapStatusClass,
            loading: false,
            page: 1,
            pageSize: 5,
            sortValue: 'lowest_score',
            tableData: []
        }
    },
    mounted() {
        this.tableData = this.customersContainer.customers;
    },
    computed: {
        displayData() {
            return this.tableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        }
    },
    methods: {
        handleCurrentChange(page) {
            this.page = page;
        },
        handlePageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectChange(value) {
            this.sortValue = value;
            this.customersContainer.sort(value);
        },
        handleClickCustomer(customer) {
            const customerID = customer.id;
            const filters = this.filterData || {};
            let newFilters = { ...filters, business_id: customerID };

            switch (this.pageType) {
                case BankPageTypes.PRODUCT_AREA: {
                    newFilters.product_areas = [this.parentTitle];
                    newFilters.countries = [this.childTitle];
                    break;
                }
                case BankPageTypes.COUNTRY: {
                    newFilters.product_areas = [this.childTitle];
                    newFilters.countries = [this.parentTitle];
                    break;
                }
            }

            store.commit('SET_SAVED_FILTERS_PREV_PAGE', {
                page: BankFiltersTypeEnum.SINGLE_CUSTOMER,
                filters: newFilters,
            });

            this.$router.push({ name: "bank-customer", params: { id: customerID } });
        }
    }
}
</script>

<style lang="scss">
.child-collapse-table {
    padding: 0 22px;

    .el-table {
        thead {
            .cell {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 15px;
                opacity: 0.5;
            }
            .stats-label .cell {
                text-align: center;
                padding: 0;
            }
        }
        tbody {
            .el-table__row {
                cursor: pointer;
            }
            .cell {
                color: #515151;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px;
            }
            .stats-label .cell {
                text-align: center;
            }
            .stats-company .cell {
                display: flex;
                align-items: center;
                gap: 20px;
                img {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
                span {

                }
            }
        }

        tr > th:first-child, tr > td:first-child {
            .cell {
                padding-left: 0;
            }
        }
    }

    &__divider {
        display: flex;
        align-items: center;
        p {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.50);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            margin-bottom: 0;
        }
        .select-container {
            margin-left: 14px;
            .el-input__prefix-inner {
                img {
                    width: 15px;
                    filter: grayscale(1);
                }
            }
        }
    }

    &__wrapper {
        position: relative;
        .child-collapse-loading {
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: #ffffff78;
            backdrop-filter: blur(4px);
        }
        &.no-data-loading {
            height: 200px;
            .child-collapse-loading {
                background: #fff;
            }
        }
    }

    .stats-values {
        &__item {
            display: flex;
            align-items: center;
            justify-content: center;

            &--indicator {
                display: block;
                width: 8.3px;
                height: 8.3px;
                background: #ECECEC;
                border-radius: 50%;
                margin-right: 4px;
            }

            &--value {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: #313131;
            }

            &--mobile-title {
                display: none;
            }

            &.score-indicator {
                &.status-danger {
                    .stats-values__item--indicator {
                        background: #E22D21;
                    }
                }

                &.status-warning {
                    .stats-values__item--indicator {
                        background: #FBDC6C;
                    }
                }

                &.status-success {
                    .stats-values__item--indicator {
                        background: #2BC490;
                    }
                }
            }

            &.importance-indicator {
                .stats-values__item--indicator {
                    background: #CED4F8;
                }
            }

            &.gap-indicator {
                .stats-values__item--indicator {
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 6px;
                    }
                }
            }

            &.gap-indicator {
                &.status-success {
                    .stats-values__item--indicator {
                        background-color: #DBF7ED;

                        &:after {
                            background-image: url("/media/buying/icons/gap-value-icon.svg");
                            filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
                        }
                    }
                }

                &.status-danger {
                    .stats-values__item--indicator {
                        background-color: #FBDDDB;

                        &:after {
                            background-image: url("/media/buying/icons/gap-value-icon.svg");
                            filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
                        }
                    }
                }
            }
        }

        @media (max-width: 992px) {
            &__item {
                padding-top: 14px;
                position: relative;
                justify-content: flex-start;

                &--mobile-title {
                    display: block;
                    position: absolute;
                    top: 0;
                    font-size: 9px;
                    color: #999999;
                }
            }
        }
    }
}
</style>
