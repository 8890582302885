<template>
    <div class="content-item__child--item">
        <div class="content-item__child--wrapper">
            <div class="content-item__child--item-header">
                <div class="table-collapsed-button" :class="{ 'isCollapsed': isCollapsed }" @click="isCollapsed = !isCollapsed">
                    <inline-svg src="/media/buying/icons/arrow-black.svg" width="12px" height="12px" />
                </div>
                <a :href="child.redirectUrl" @click.prevent="$emit('onChildItemClick')">
                    <div v-if="child.iconPath" class="child-item-name-image">
                        <img :src="child.iconPath" alt="">
                        <span>{{ child.name }}</span>
                    </div>
                    <div v-else class="child-item-name">
                        {{ child.name }}
                    </div>
                </a>
            </div>
            <div class="child-item-stats">
                <DashboardStatsValues
                  :score="child.score"
                  :importance="child.importance"
                  :gap="child.gap"
                  :respondents="child.respondents"
                  :scores="child.scores"
                />
            </div>
        </div>
        <Transition name="fade">
            <div class="content-item__child--collapsed" v-if="isCollapsed">
                <DashboardChildCollapseTable
                    :parent-title="parentTitle"
                    :child-title="child.name"
                    :customers-container="child.customersContainer"
                    :filter-data="filterData"
                    :page-type="pageType"
                />
            </div>
        </Transition>
    </div>
</template>

<script>
import DashboardStatsValues from "@/buying-teams/shared-components/dashboard/DashboardStatsValues.vue";
import DashboardChildCollapseTable from "@/buying-teams/shared-components/dashboard/DashboardChildCollapseTable.vue";

export default {
    name: 'DashboardChildCollapseTableItem',
    components: {
        DashboardChildCollapseTable,
        DashboardStatsValues
    },
    props: {
        child: Object,
        filterData: {
            type: Object,
            default: null
        },
        parentTitle: String,
        pageType: String,
    },
    data() {
        return {
            isCollapsed: false
        }
    }
}
</script>
